import React, { useState, useEffect } from 'react';
import Icon from '../../../ui/shared/Icon';
import DropdownV2 from '../../../ui/shared/DropdownV2/DropdownV2';
import CssBaseline from '@mui/material/CssBaseline';
import { openToast } from '../../../helpers/toast';
import { appState, logoutChild, handleDataGridStateChange } from '../../../index';
import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, type GridInitialState, GridToolbar, type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid';
import { CircularProgress } from '@mui/material';
import { t } from 'i18next';
import Menu, { MenuItem } from 'rc-menu';
import CreateAccount from '../../../ui/shared/Modals/system-admin/CreateAccount.modal';
import ProcessErrorsModal from '../../../ui/shared/Modals/system-admin/ProcessErrors.modal';
import FCPModal from '../../../ui/shared/Modals/system-admin/FCP.modal';
import axios from 'axios';
import EditAccountModal from '../../../ui/shared/Modals/system-admin/EditAccount.modal';
import { type Account } from '../../../types/systemAdmin.d';
import SearchableDropdown from '../../../ui/shared/SearchableDropdown';

const SystemAdmin: React.FC = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [resellers, setResellers] = useState<any>({});
  const [resellerNames, setResellerNames] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [selectedParentAccount, setSelectedParentAccount] = useState('All');
  const [selectedRegion, setSelectedRegion] = useState('All');
  const [filteredData, setFilteredData] = useState<Account[]>([]);

  useEffect(() => {
    logoutChild();
    void getResellers();
    void getAccounts();
    setRowCountState((prevRowCountState: number): number =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount]);

  const getResellers = async (): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/get-resellers`;
    try {
      const response = await axios.get(url);
      if (response.data) {
        let namesIds: { 'Super Admin': number, None: null, } = { None: null, 'Super Admin': 1 };
        namesIds = { ...namesIds, ...response.data };
        const names: string[] = [];
        setResellers(namesIds);
        for (const key in namesIds) {
          names.push(key);
        }
        setResellerNames(names.sort((nameA: any, nameB: any) => {
          const keyA = nameA.toLowerCase();
          const keyB = nameB.toLowerCase();
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAccounts = async (): Promise<void> => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}` +
        '/api/accounts-fuel-card-changes?include=primaryUser,accountSettings,fuelCardProviders,geotabUser';
      const response = await axios.get(url);
      if (response.data.data) {
        const transformedData = transformData(response.data.data);
        setRowCount(response.data.data.length);
        setAccounts(transformedData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createAccountSettings = async (id: number): Promise<void> => {
    const url: string = '/api/account-settings';
    const params: { account_id: number, preferred_match: string, } = {
      account_id: id,
      preferred_match: 'vehicle'
    };
    try {
      await axios.post(url, params);
    } catch (err) {
      console.log(err);
      openToast({
        type: 'error',
        label: 'Error creating account settings',
        autoClose: 2000,
        theme: 'dark'
      });
    }
  };

  const createAccountToFCP = async (id: number, providerUniqueId: string, providerCode: string): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/account-to-fcps`;
    const params: { fcp_code: string, account_id: number, fcp_company_unique_id: string, } = {
      account_id: id,
      fcp_company_unique_id: providerUniqueId,
      fcp_code: providerCode
    };
    try {
      await axios.post(url, params);
    } catch (error: any) {
      console.log(error);
    }
  };
  const createAccount = async (values: any): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/accounts`;
    const renewalDate: Date = new Date();
    const status: string = 'active';
    renewalDate.setDate((renewalDate.getDate() + 30));
    const params: { renewal_date: string, gps_service: string, name: any, status: string, } = {
      name: values.name,
      gps_service: 'Geotab',
      renewal_date: renewalDate.toISOString().substring(0, 10),
      status
    };
    try {
      const accountCreationResponse = await axios.post(url, params);
      const account = accountCreationResponse.data.data.account;
      const currentItems: any[] = [...accounts];
      currentItems.push({
        id: account.id,
        name: account.name,
        parentAccount: 'None',
        parentAccountId: null,
        primaryUser: 'None',
        primaryUserId: null,
        status: true,
        accountLevel: 'customer',
        gpsService: 'Geotab',
        useDefaultFuelType: false,
        region: 'US',
        ignoreOON: false,
        pushToGeotab: true,
        fuelCardErrors: false,
        fuelTransactionErrors: false,
        fuelCardChange: 0,
        latestTransaction: null,
        useAnnotations: false,
        defaultFuelType: false,
        serviceAccountStatus: 'Update Required',
        alterDays: false
      });
      setAccounts(currentItems);
      void createAccountSettings(account.id);
      void createAccountToFCP(account.id, values.providerUniqueId, values.providerCode);
      openToast({
        type: 'success',
        label: 'Account created',
        autoClose: 2000,
        theme: 'dark'
      });
    } catch (err) {
      console.log(err);
      openToast({
        type: 'error',
        label: 'Error creating account',
        autoClose: 2000,
        theme: 'dark'
      });
    }
  };

  const transformData = (accounts: any): any => {
    if (!accounts) {
      return [];
    }
    return accounts.map((account: any): Account => (
      {
        id: account.id,
        name: account.name,
        parentAccount: getResellerNameByID(account.parent_account_id),
        primaryUser: account.primary_user?.name ?? 'None',
        primaryUserId: account.primary_user_id,
        status: account.status === 'active',
        accountLevel: account.account_level,
        region: account.region,
        ignoreOON: account.account_settings?.ignore_oon ?? false,
        pushToGeotab: account.push_to_geotab === 1,
        fuelCardErrors: account.account_settings?.email_process_file_errors.fuel_card,
        fuelTransactionErrors: account.account_settings?.email_process_file_errors.fuel_transaction,
        fuelCardChange: account?.fuelCardChangedCount ?? 0,
        latestTransaction: account?.latestTransaction,
        useAnnotations: account.account_settings?.use_annotations,
        defaultFuelType: account.use_default_fuel_type,
        email: account?.geotab_user?.username,
        database: account?.geotab_user?.database,
        path: account?.geotab_user?.path,
        geotabUserId: account.geotab_user_id,
        serviceAccountStatus: account.service_account_status ? '' : 'Update Required',
        alterDays: account.account_settings?.alter_days,
        lastSuccessfulSync: account.last_successful_sync
      }
    ));
  };

  const getResellerNameByID = (id: number): string => {
    for (const key in resellers) {
      if (Object.prototype.hasOwnProperty.call(resellers, key)) {
        if (resellers[key] === id) {
          setLoading(false);
          return key;
        }
      }
    }
    return '';
  };

  const changeParentAccount = async (parentId: number, id: number): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${id}`;
    const params: { parent_account_id: number, } = {
      parent_account_id: parentId
    };
    try {
      await axios.put(url, params);
    } catch (err) {
      console.log(err);
    }
  };

  const changePrimaryUser = async (userId: number, id: number): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${id}`;
    const params: { primary_user_id: number, } = {
      primary_user_id: userId
    };
    try {
      await axios.put(url, params);
      openToast({
        type: 'success',
        label: t('Primary user updated'),
        autoClose: 2000,
        theme: 'dark'
      });
    } catch (err) {
      console.log(err);
    }
  };

  const changeAccountLevel = async (accountLevel: string, id: number): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${id}`;
    const params: { account_level: string, } = {
      account_level: accountLevel
    };
    try {
      await axios.put(url, params);
    } catch (err) {
      console.log(err);
    }
  };
  const changeStatus = async (checked: boolean, id: number): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${id}`;
    let status;
    if (checked) {
      status = 'active';
    } else {
      status = 'inactive';
    }
    const params: { status: any, } = {
      status
    };
    try {
      await axios.put(url, params);
    } catch (err) {
      console.log(err);
    }
  };

  const toggleDefaultFuelType = async (checked: boolean, id: number): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${id}`;
    const params: { use_default_fuel_type: boolean, } = {
      use_default_fuel_type: checked
    };
    try {
      await axios.put(url, params);
    } catch (err) {
      console.log(err);
    }
  };
  const changeRegion = async (region: string, id: number): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${id}`;
    const params: { region: string, } = {
      region
    };
    try {
      await axios.put(url, params);
    } catch (err) {
      console.log(err);
    }
  };

  const togglePushToGeotab = async (checked: boolean, id: number): Promise<void> => {
    const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${id}`;
    let pushToGeotab;
    if (checked) {
      pushToGeotab = 1;
    } else {
      pushToGeotab = 0;
    }
    const params: { push_to_geotab: any, } = {
      push_to_geotab: pushToGeotab
    };
    try {
      await axios.put(url, params);
    } catch (err) {
      console.log(err);
    }
  };

  const handleParentAccountChange = (selectedOption: string): void => {
    setSelectedParentAccount(selectedOption);
  };

  const handleRegionChange = (selectedOption: string): void => {
    setSelectedRegion(selectedOption);
  };

  useEffect(() => {
    let filteredAccounts = accounts;

    if (selectedParentAccount !== 'All') {
      filteredAccounts = filteredAccounts.filter(account => account.parentAccount === selectedParentAccount);
    }

    if (selectedRegion !== 'All') {
      filteredAccounts = filteredAccounts.filter(account => account.region === selectedRegion);
    }

    setFilteredData(filteredAccounts);
  }, [accounts, selectedParentAccount, selectedRegion]);

  const toggleAlterDays = async (checked: boolean, id: number): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/api/account-settings/${id}`;
    const params = {
      alter_days: checked
    };
    try {
      await axios.put(url, params);
    } catch (err) {
      console.log(err);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Account Name'),
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <EditAccountModal
            key={'EditAccountModal' + `${params.id}`}
            accountParams={params}
            updateUser={changePrimaryUser}
            changeStatus={changeStatus}
            accounts={accounts}
            setAccounts={setAccounts}
            togglePushToGeotab={togglePushToGeotab}
            toggleDefaultFuelType={toggleDefaultFuelType}
            toggleAlterDays={toggleAlterDays}
          />
        );
      }
    },
    {
      field: 'parentAccount',
      headerName: t('Parent Account'),
      width: 215,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <SearchableDropdown
            key={'parentAccount' + `${params.row.id}`}
            className="parent-dropdown"
            name={'parentAccount' + `${params.row.id}`}
            options={resellerNames.filter((resellerName: string) => (
              resellerName !== params.row.name
            )).map((item: string) => ({
              value: item,
              label: item
            }))}
            selectedVal={params.row?.parentAccount ?? ''}
            handleChange={(parentAccount: string) => {
              void changeParentAccount(resellers[parentAccount], params.row.id);
              setAccounts(accounts.map((currentItem: any): any => {
                if (currentItem.id === params.row.id) {
                  return {
                    ...currentItem,
                    parentAccount
                  };
                }
                return currentItem;
              }));
            }}
          />
        );
      }
    },
    {
      field: 'accountLevel',
      headerName: t('Account Type'),
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <SearchableDropdown
            key={'accountLevel' + `${params.row.id}`}
            className="type-dropdown"
            name={'accountLevel' + `${params.row.id}`}
            options={['customer', 'reseller', 'superadmin'].map((item: string) => ({
              value: item,
              label: item
            }))}
            selectedVal={params.row?.accountLevel ?? ''}
            handleChange={(accountLevel: string) => {
              void changeAccountLevel(accountLevel, params.row.id);
              setAccounts(accounts.map((currentItem: any): any => {
                if (currentItem.id === params.row.id) {
                  return {
                    ...currentItem,
                    accountLevel
                  };
                }
                return currentItem;
              }));
            }}
          />
        );
      }
    },
    {
      field: 'region',
      headerName: t('Region'),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <DropdownV2
            key={'region' + `${params.row.id}`}
            menu={(
              <Menu
                key={'regionMenu' + `${params.row.id}`}
              >
                {[
                  'US',
                  'Canada',
                  'Mexico'
                ].map((item: string, index: number) => {
                  return (
                    <MenuItem
                      key={item + `${index}`}
                      className="cursor-pointer"
                      onClick={(): void => {
                        void changeRegion(item, params.row.id);
                        setAccounts(accounts.map((currentItem: any) => {
                          if (currentItem.id === params.row.id) {
                            return {
                              ...currentItem,
                              region: item
                            };
                          }

                          return currentItem;
                        }));
                      }}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Menu>
            )}
          >
            <button
              type="button"
              className="flex items-center text-left gap-x-2 w-full"
            >
              <span className="truncate">{params.row.region}</span>
              <Icon
                name="caret-down"
                key={'regionIcon' + `${params.row.id}`}
              />
            </button>
          </DropdownV2>
        );
      }
    },
    {
      field: 'fcp',
      headerName: t('FCP'),
      width: 75,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <FCPModal
            key={'fcp' + `${params.row.id}`}
            params={params}
          />
        );
      }
    },
    {
      field: 'fuelCardChange',
      headerName: 'Fuel Cards Changed',
      width: 150
    },
    {
      field: 'latestTransaction',
      headerName: 'Latest Transaction',
      width: 170,
      renderCell: (params: GridRenderCellParams) => {
        const transactionDate = new Date(params.value);
        const currentDate = new Date();
        const diffInWeeks = Math.floor((currentDate.getTime() - transactionDate.getTime()) / (1000 * 60 * 60 * 24 * 7));
        let color;

        if (diffInWeeks > 2) {
          color = 'red';
        } else if (diffInWeeks > 1) {
          color = 'seagreen';
        } else {
          color = 'white';
        }

        return (
          <div style={{ color }}>
            {params.value}
          </div>
        );
      }
    },
    {
      field: 'lastSuccessfulSync',
      headerName: 'Latest Sync',
      width: 150
    },
    {
      field: 'serviceAccountStatus',
      headerName: 'Service Account Status',
      width: 200
    }
  ];

  const theme = createTheme({
    palette: {
      mode: 'dark'
    }
  });

  return (
    <>
      {loading && (
        <div
          className="p-4 rounded-xl absolute w-full h-full flex justify-center loading items-center bg-opacity-50 bg-black"
        >
          <CircularProgress/>
        </div>
      )}
      <div className="bg-dark-3 p-4 rounded-xl">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-x-2 text-custom-gray-light">
            <Icon name="user-lock"/>
            <span>{t('System Admin')}</span>
          </div>
          <div className="flex items-center gap-x-3">
            <SearchableDropdown
              name="parentAccount"
              selectedVal={selectedParentAccount}
              options={[
                { value: 'All', label: 'All' },
                ...resellerNames.map((name) => ({ value: name, label: name }))
              ]}
              handleChange={handleParentAccountChange}
              className="medium-dropdown"
            />
            <SearchableDropdown
              name="region"
              selectedVal={selectedRegion}
              options={[
                { value: 'All', label: 'All' },
                { value: 'US', label: 'US' },
                { value: 'Canada', label: 'Canada' },
                { value: 'Mexico', label: 'Mexico' }
              ]}
              handleChange={handleRegionChange}
              className="small-dropdown"
            />
            <CreateAccount
              createAccount={createAccount}
            />
            <ProcessErrorsModal
              accounts={accounts}
              setAccounts={setAccounts}
            />
          </div>
        </div>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <DataGrid
            onStateChange={(state: GridInitialState): void => {
              handleDataGridStateChange('systemAdmin', state);
            }}
            initialState={appState.dataGridColumnState.value.systemAdmin ?? {}}
            disableDensitySelector={true}
            hideFooter={true}
            localeText={{
              noRowsLabel: t('No rows'),
              toolbarColumns: t('Columns'),
              toolbarColumnsLabel: t('Columns'),
              toolbarDensity: t('Density'),
              toolbarDensityLabel: t('Density'),
              toolbarDensityCompact: t('Compact'),
              toolbarDensityStandard: t('Standard'),
              toolbarDensityComfortable: t('Comfortable'),
              toolbarQuickFilterPlaceholder: `${t('Search')}...`,
              MuiTablePagination: {
                labelRowsPerPage: t('Rows per Page'),
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
              }
            }}
            pagination
            rowCount={rowCountState}
            rows={[...filteredData].sort((accountA: any, accountB: any) => {
              const keyA = accountA.name.toLowerCase();
              const keyB = accountB.name.toLowerCase();
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            })}
            paginationMode="server"
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            density="compact"
            slotProps={{
              toolbar: { // Disable Export Option
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true }
              }
            }}
            slots={{ toolbar: GridToolbar }}
            className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
            sx={{
              height: '50rem',
              '& .MuiDataGrid-row': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                marginTop: '4px',
                borderBottom: 'none'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              '& .MuiDataGrid-cell': {
                border: 'none'
              },
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                borderBottom: 'none',
                marginBottom: '10px'
              },
              border: 0
            }}
          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default withTranslation()(SystemAdmin);
