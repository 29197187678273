// src/api.js
import axios from 'axios';
import {
  type AccountToFcpResponse,
  type AccountToFcp,
  type PreferredMatch,
  type GeotabDriver,
  type GeotabVehicle
} from '../types/fcp.d';
import { openToast } from './toast';
import { t } from 'i18next';
import { appState } from '..';

export const fetchGeotabDrivers = async (): Promise<GeotabDriver[] | null> => {
  const url: string = `${process.env.REACT_APP_API_URL}/api/get-drivers-from-geotab`;
  try {
    const response = await axios.get(url);
    if (response.data) {
      return [...response.data].sort((driverA: GeotabDriver, driverB: GeotabDriver) => {
        if (driverA.name < driverB.name) return -1;
        if (driverA.name > driverB.name) return 1;
        return 0;
      });
    }
  } catch (error) {
    console.error('Error fetching geotab drivers: ', error);
    openToast({
      type: 'error',
      label: t('Error fetching Geotab drivers'),
      autoClose: 2000,
      theme: 'dark'
    });
  }
  return null;
};

export const fetchGeotabVehicles = async (): Promise<GeotabVehicle[] | null> => {
  const url = `${process.env.REACT_APP_API_URL}/api/geotab-vehicles-minimized`;
  try {
    const response = await axios.post(url);
    if (response.data.data) {
      return [...response.data.data].sort((deviceA: any, deviceB: any) => {
        if (deviceA.name < deviceB.name) return -1;
        if (deviceA.name > deviceB.name) return 1;
        return 0;
      });
    }
  } catch (error) {
    console.error('Error fetching geotab vehicles: ', error);
    openToast({
      type: 'error',
      label: t('Error fetching Geotab vehicles'),
      autoClose: 2000,
      theme: 'dark'
    });
  }
  return null;
};

export const fetchUniqueAccountToFcp = async (accountId: number | string | null): Promise<AccountToFcp[] | null> => {
  if (!accountId) {
    return null;
  }
  const url: string = `${process.env.REACT_APP_API_URL}/api/accounts-unique-fcps/${accountId}`;

  try {
    const response = await axios.get(url);
    if (response.data.data) {
      return response.data.data.map((fcp: AccountToFcpResponse, index: number): AccountToFcp => {
        return {
          id: index,
          fuelCardProvider: fcp.fcp_code,
          uniqueField: fcp.unique_card_id_field,
          preferredMatch: fcp.preferred_match as PreferredMatch
        };
      });
    }
  } catch (error: any) {
    console.error(error);
    openToast({
      type: 'error',
      label: t('No fuel card providers found for this account'),
      autoClose: 2000,
      theme: 'dark'
    });
    return null;
  }
  return null;
};

export const getParentAuthHeader = (): { authorization: any, } | undefined => {
  let headers;
  if (appState.parentData.value) {
    const parentDataObject = JSON.parse(appState.parentData.value);
    if (parentDataObject?.userRole === 'super_admin' && parentDataObject?.token) {
      headers = {
        authorization: parentDataObject.token
      };
    }
  }
  return headers;
};
