import React, { useState } from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import { withTranslation } from 'react-i18next';
import Input from '../../Formik/Input';
import { Formik, type FormikValues } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import Button from '../../Button';
import DeleteItemModal from '../DeleteItem.modal';
import axios from 'axios';
import { openToast } from '../../../../helpers/toast';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { type GridRenderCellParams } from '@mui/x-data-grid';
import ChangePrimaryUserModal from './ChangePrimaryUser.modal';
import ManageUsersModal from './ManageUsers.modal';
import EditServiceAccountModal from '../../Modals/system-admin/UpdateServiceAccount.modal';
import Switch from '../../Switch';
import { type Account } from '../../../../types/systemAdmin.d';

interface Props {
  accountParams: GridRenderCellParams;
  updateUser: (userId: number, id: number) => Promise<void>;
  changeStatus: (checked: boolean, id: number) => Promise<void>;
  accounts: Account[];
  togglePushToGeotab: (checked: boolean, id: number) => Promise<void>;
  toggleDefaultFuelType: (checked: boolean, id: number) => Promise<void>;
  setAccounts: React.Dispatch<Account[]>;
  toggleAlterDays: (checked: boolean, id: number) => Promise<void>;
}

const EditAccountSchema = Yup.object().shape({
  account_name: Yup.string().required(t('Required'))
});

const EditAccountModal: React.FC<Props> = ({
  accountParams,
  updateUser,
  changeStatus,
  accounts,
  setAccounts,
  togglePushToGeotab,
  toggleDefaultFuelType,
  toggleAlterDays
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const editAccountDetails = async (submittedValues: any): Promise<void> => {
    try {
      const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${accountParams.row.id}`;
      const params: { name: string, } = {
        name: submittedValues.account_name
      };
      await axios.put(url, params);
      accountParams.row.name = submittedValues.account_name;
      setIsShown(false);
    } catch (error: any) {
      console.log(error);
      openToast({
        type: 'error',
        label: t('Error updating account'),
        autoClose: 2000,
        theme: 'dark'
      });
    }
  };

  const deleteAccount = async (): Promise<void> => {
    try {
      const url: string = `${process.env.REACT_APP_API_URL}/api/accounts/${accountParams.row.id}`;
      if (!await axios.delete(url)) throw Error('Account can not be deleted');
      setAccounts(accounts.filter((account: Account): boolean => account.id !== accountParams.row.id));
      onHide();
    } catch (error: any) {
      console.error(error);
      openToast({
        type: 'error',
        label: t(error?.message ?? 'Error deleting account'),
        autoClose: 2000,
        theme: 'dark'
      });
    }
  };

  const onHide = (): void => {
    setIsShown(false);
  };

  return (
    <>
      <button
        type="button"
        className="text-custom-blue-normal hover:text-custom-blue-hover"
        onClick={(): void => {
          setIsShown(true);
        }}
      >
        {accountParams.row.name}
      </button>
      {isShown && <Modal
          key={'fcpModal' + `${accountParams.row.id}`}
          header={(
            <div className="flex items-center gap-x-2">
              <Icon name="edit"/>
              <p>{t('Edit Account')}</p>
            </div>
          )}
          show={isShown}
          onHide={onHide}
          maxWidth={'35rem'}
          maxHeight={'50rem'}
      >
          <Formik
              enableReinitialize
              initialValues={{
                account_name: accountParams.row.name
              }}
              validationSchema={EditAccountSchema}
              onSubmit={(values: FormikValues, { setSubmitting }): void => {
                void editAccountDetails(values);
                setTimeout((): void => {
                  setSubmitting(false);
                }, 400);
              }}
          >
            {(props: any) => {
              return (
                <form
                  onSubmit={props.handleSubmit}
                  className="mt-4 flex flex-col gap-y-1"
                >
                  <div className="flex flex-col gap-y-6">
                    <Input
                      name="account_name"
                      type="default"
                      label={t('Account Name')}
                      value={props.values.account_name}
                    />
                  </div>
                    <Box sx={{ width: '100%' }}>
                      <Grid
                        className="bg-dark-3 !w-full !px-2 !m-0 text-sm flex justify-start text-center rounded-3xl"
                        container
                        rowSpacing={4}
                        columnSpacing={{ xs: 0 }}
                      >
                        <Grid
                          item
                          xs={4}
                        >
                          <p className="text-xs">
                            Primary User
                          </p>
                          <ChangePrimaryUserModal
                            key={'primaryUser' + `${accountParams.row.id}`}
                            params={accountParams}
                            updateUser={updateUser}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                        >
                          <p className="text-xs">
                            Manage Users
                          </p>
                          <ManageUsersModal
                            key={'update' + `${accountParams.row.id}`}
                            accountRow={accountParams}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                        >
                          <p className="text-xs">
                            Service Account
                          </p>
                          <EditServiceAccountModal
                            key={`editUserLogin${accountParams.row.id}`}
                            params={accountParams}
                            accounts={accounts}
                            setAccounts={setAccounts}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                        >
                          <Switch
                            label="Active"
                            key={'status' + `${accountParams.row.id}`}
                            checked={accountParams.row.status}
                            onClick={(newValue: boolean): void => {
                              void changeStatus(newValue, accountParams.row.id);
                              setAccounts(accounts.map((item: any): any => {
                                if (item.id === accountParams.row.id) {
                                  return {
                                    ...item,
                                    status: newValue
                                  };
                                }
                                return item;
                              }));
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                        >
                          <Switch
                            label="Push to Geotab"
                            key={'pushToGeotab' + `${accountParams.row.id}`}
                            checked={accountParams.row.pushToGeotab}
                            onClick={(newValue: boolean) => {
                              void togglePushToGeotab(newValue, accountParams.row.id);
                              setAccounts(accounts.map((item: any) => {
                                if (item.id === accountParams.row.id) {
                                  return {
                                    ...item,
                                    pushToGeotab: newValue
                                  };
                                }
                                return item;
                              }));
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                        >
                          <Switch
                            label="Defaut Fuel Type"
                            key={'defaultFuelType' + `${accountParams.row.id}`}
                            checked={accountParams.row.defaultFuelType}
                            onClick={(newValue: boolean) => {
                              void toggleDefaultFuelType(newValue, accountParams.row.id);
                              setAccounts(accounts.map((item: any) => {
                                if (item.id === accountParams.row.id) {
                                  return {
                                    ...item,
                                    defaultFuelType: newValue
                                  };
                                }
                                return item;
                              }));
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                        >
                          <Switch
                            label="Alter Time Range"
                            key={'alterDays' + `${accountParams.row.id}`}
                            checked={accountParams.row.alterDays}
                            onClick={(newValue: boolean) => {
                              void toggleAlterDays(newValue, accountParams.row.id);
                              setAccounts(accounts.map((item: any) => {
                                if (item.id === accountParams.row.id) {
                                  return {
                                    ...item,
                                    alterDays: newValue
                                  };
                                }
                                return item;
                              }));
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  <div className="flex gap-5">
                    <Button
                      disabled={props.isSubmitting}
                      className="!px-12"
                    >
                      {t('Submit')}
                    </Button>
                    <DeleteItemModal
                      header="Delete account"
                      message="Are you sure you want to delete this account? All related data will be deleted."
                      onDelete={(): void => {
                        void deleteAccount();
                      }}
                      button={true}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
      </Modal>}
    </>
  );
};

export default withTranslation()(EditAccountModal);
