import React, { useEffect, useState } from 'react';
import IconButton from '../../IconButton';
import Modal from '../../Modal';
import Icon from '../../Icon';
import { Formik, type FormikValues } from 'formik';
import Input from '../../Formik/Input';
import Button from '../../Button';
import axios, { AxiosError } from 'axios';
import { appState } from '../../../../index';
import { t } from 'i18next';
import Switch from '../../Switch';
import { openToast } from '../../../../helpers/toast';
import CircularProgress from '@mui/material/CircularProgress';
import * as Yup from 'yup';
import { type AccountSettings, type Day } from '../../../../types/account.d';
import { type UserSettings } from '../../../../types/user.d';

interface Props {
  show: boolean;
  onHide: () => void;
  openModal: () => void;
  fetchTransactions: () => void;
}

const fillingInfoSettingsSchema = Yup.object().shape({
  filling_time_threshold: Yup.number().typeError('Please enter a valid number').max(1000, 'Filling time threshold must be less than or equal to 1000').required(),
  filling_percent_threshold: Yup.number().typeError('Please enter a valid number').max(100, 'Filling percent threshold must be less than or equal to 100').required()
});

const possibleDays: Day[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

const FillingInfoModal: React.FC<Props> = ({
  show,
  onHide,
  openModal,
  fetchTransactions
}) => {
  const [accountSettings, setAccountSettings] = useState<AccountSettings | null>(null);
  const [startTime, setStartTime] = useState<string>('08:00');
  const [endTime, setEndTime] = useState<string>('17:00');
  const [days, setDays] = useState<Day[]>([]);
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);

  const getFormattedTime = (totalSeconds: number): string => {
    const sec = totalSeconds % 60;
    totalSeconds = (totalSeconds - sec) / 60;
    const min = totalSeconds % 60;
    const hour = `${(totalSeconds - min) / 60}`;
    const formattedHour = hour.padStart(2, '0');
    const formattedMinutes = `${min}`.padStart(2, '0');
    return formattedHour + ':' + formattedMinutes;
  };

  const fuelDifferenceThresholdUnitMap = {
    metric: [{ label: t('Liters'), value: 'unit' }, { label: t('Percent'), value: 'percent' }],
    imperial: [{ label: t('Gallons'), value: 'unit' }, { label: t('Percent'), value: 'percent' }]
  };
  const fuelDifferenceThresholdUnitOptions = userSettings?.unit_of_measure === 'metric' ? fuelDifferenceThresholdUnitMap.metric : fuelDifferenceThresholdUnitMap.imperial;

  const fetchAccountSettings = async (): Promise<void> => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/account-settings?filter=account_id:${appState.accountId.value}(eq)`
      );
      if (response.data.data[0]) {
        response.data.data[0].filling_time_threshold = response.data.data[0].filling_time_threshold / 60;
        setAccountSettings(response.data.data[0]);
        setDays(response.data.data[0].days);
        setStartTime(getFormattedTime(response.data.data[0].fi_start_time));
        setEndTime(getFormattedTime(response.data.data[0].fi_end_time));
      }
    } catch (error) {
      console.error('Error fetching account settings:', error);
    }
  };
  const fetchUserSettings = async (): Promise<void> => {
    const userSettingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-settings?filter=user_id:${appState.userId.value}(eq)`);
    if (!userSettingsResponse.data.data.length) {
      return;
    }
    setUserSettings(userSettingsResponse.data.data[0]);
  };

  const updateFillingInfo = async (formValues: FormikValues): Promise<boolean> => { // returns true or false to show or hide modal
    // Prevents visible input from changing during submission
    const updatesSubmitted = { ...formValues };
    if (!updatesSubmitted || !accountSettings || !userSettings) {
      return false;
    }

    try {
      if (updatesSubmitted.fuel_difference_threshold_unit.toLowerCase() === 'gallons' || updatesSubmitted.fuel_difference_threshold_unit === 'unit') {
        updatesSubmitted.fuel_difference_threshold_unit = 'unit';
      } else if (updatesSubmitted.fuel_difference_threshold_unit.toLowerCase() === 'percent') {
        updatesSubmitted.fuel_difference_threshold_unit = 'percent';
      } else {
        console.error('Invalid unit selection');
        return false;
      }
      updatesSubmitted.fi_start_time = startTime;
      updatesSubmitted.fi_end_time = endTime;
      updatesSubmitted.days = days;
      const alerts = {
        no_vehicle_location: false,
        is_location_mismatch: false,
        is_fuel_level_mismatch: false,
        is_fuel_type_mismatch: false,
        is_filling_time_alert: false,
        is_filling_percent_alert: false
      };
      if (updatesSubmitted.filling_time_threshold !== accountSettings.filling_time_threshold ||
          updatesSubmitted.fi_start_time !== getFormattedTime(accountSettings.fi_start_time) ||
          updatesSubmitted.fi_end_time !== getFormattedTime(accountSettings.fi_end_time) ||
          JSON.stringify(updatesSubmitted.days) !== JSON.stringify(accountSettings.days)) {
        alerts.is_filling_time_alert = true;
      }
      if (updatesSubmitted.filling_percent_threshold !== accountSettings.filling_percent_threshold) {
        alerts.is_filling_percent_alert = true;
      }
      updatesSubmitted.filling_time_threshold = (updatesSubmitted.filling_time_threshold ? updatesSubmitted.filling_time_threshold * 60 : (accountSettings as any).filling_time_threshold);
      updatesSubmitted.filling_percent_threshold = (updatesSubmitted.filling_percent_threshold ?? (accountSettings as any).filling_percent_threshold);

      updatesSubmitted._method = 'PUT';
      await axios.put(`${process.env.REACT_APP_API_URL}/api/account-settings/${updatesSubmitted.id}`, updatesSubmitted);
      await axios.put(`${process.env.REACT_APP_API_URL}/api/set-alert-types/${appState.accountId.value}`, { alertsToReprocess: alerts });
      openToast({ type: 'success', label: t('Filling info settings updated'), autoClose: 3000, theme: 'dark' });
      fetchTransactions();
      return true;
    } catch (error: AxiosError | any) {
      console.error('Error updating account settings: ', error);
      let message = 'Error updating account settings';
      if (error instanceof AxiosError) {
        message = error?.response?.data.message;
      }
      openToast({
        type: 'error',
        label: t(message),
        autoClose: 2000,
        theme: 'dark'
      });
      onHide();
      return false;
    }
  };

  useEffect((): void => {
    if (show) {
      void fetchUserSettings();
      void fetchAccountSettings();
    }
  }, [show]);

  return (
    <>
      <IconButton
        icon="gear"
        onClick={() => {
          openModal();
        }}
      />
      <Modal
        header={(
          <div className="flex items-center gap-x-2">
            <Icon name="gear"/>
            <p>{t('Account Settings: Fueling Efficiency')}</p>
          </div>
        )}
        show={show}
        onHide={onHide}
        maxWidth={accountSettings?.alter_days ? '25rem' : '10rem'}
      >
        <Formik
          enableReinitialize
          initialValues={{
            ...accountSettings
          }}
          validationSchema={fillingInfoSettingsSchema}
          onSubmit={async (dataSubmitted: FormikValues, { setSubmitting }) => {
            const hide = await updateFillingInfo(dataSubmitted);
            setSubmitting(false);
            if (hide) {
              onHide();
            }
          }}
        >
          {(props: any) => {
            return (
              <form
                onSubmit={props.handleSubmit}
                className="mt-4"
              >
                {props.isSubmitting && (<div className="loading-overlay"><CircularProgress/></div>)}
                <div className={accountSettings?.alter_days ? 'flex justify-center gap-x-10' : ''}>
                  <div className="flex flex-col gap-y-6">
                    <Input
                      name="distance_threshold"
                      type="number"
                      label={`${t('Distance Threshold')} ${userSettings?.unit_of_measure === 'metric' ? `(${t('Kilometers')})` : `(${t('Miles')})`}`}
                      defaultValue={accountSettings?.distance_threshold}
                    />
                    <Input
                      name="fuel_difference_threshold"
                      type="number"
                      label={`${t('Fuel Difference Threshold')} ${props.values?.fuel_difference_threshold_unit
                        ? ((props.values.fuel_difference_threshold_unit.toLowerCase() === 'percent')
                          ? `(${t('Percent')})`
                          : userSettings?.unit_of_measure === 'metric'
                            ? `(${t('Liters')})`
                            : `(${t('Gallons')})`)
                        : ''}`}
                      defaultValue={accountSettings?.fuel_difference_threshold}
                    />
                    <Input
                      name="fuel_difference_threshold_unit"
                      options={fuelDifferenceThresholdUnitOptions.map(option => option.label)}
                      type="select"
                      label={t('Fuel Level Difference Unit')}
                      defaultValue={
                        !accountSettings
                          ? ''
                          : accountSettings.fuel_difference_threshold_unit === 'unit' ? fuelDifferenceThresholdUnitOptions[0].label : t('Percent')
                      }
                    />
                    <Input
                      name="filling_time_threshold"
                      type="number"
                      label={`${t('Filling Time Threshold (Minutes)')}`}
                      defaultValue={props.values.filling_time_threshold}
                    />
                    <Input
                      name="filling_percent_threshold"
                      type="number"
                      label={t('Filling Percent Threshold')}
                      defaultValue={props.values.filling_percent_threshold}
                    />
                  </div>
                  <div>
                    {accountSettings?.alter_days
                      ? <>
                        <div className="flex flex-col gap-y-6">
                          <div>
                            <label
                                htmlFor="fi_start_time"
                                className="w-full text-xs"
                            >
                              Start time
                            </label>
                            <input
                                className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs"
                                type="time"
                                step="1800"
                                id="fi_start_time"
                                name="fi_start_time"
                                value={startTime}
                                onChange={(event) => {
                                  setStartTime(event.target.value);
                                }}
                            />
                          </div>
                          <div>
                            <label
                                htmlFor="fi_end_time"
                                className="w-full text-xs"
                            >
                              End time
                            </label>
                            <input
                                className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs"
                                type="time"
                                step="1800"
                                id="fi_end_time"
                                name="fi_end_time"
                                value={endTime}
                                onChange={(event) => {
                                  setEndTime(event.target.value);
                                }}
                            />
                          </div>
                        </div>
                        <div className="flex mt-6">
                          <div className="w-1/2 flex flex-col gap-y-2">
                            <p className="text-sm">Active Days</p>
                            {possibleDays.map((dayInput: string) => {
                              return (
                                <Switch
                                    key={dayInput}
                                    label={dayInput}
                                    labelPosition="right"
                                    checked={days?.includes(dayInput as Day)}
                                    onClick={(isEnabled: boolean) => {
                                      const updatedDays = isEnabled
                                        ? [...days, dayInput as Day]
                                        : days.filter((day: Day) => day !== dayInput);
                                      setDays(updatedDays.sort((a: Day, b: Day) => possibleDays.indexOf(a) - possibleDays.indexOf(b)));
                                    }}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </>
                      : <></>}
                    </div>
                  </div>
                <Button
                    disabled={props.isSubmitting}
                    className="!px-12 mb-4"
                >
                  {t('Submit')}
                </Button>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default FillingInfoModal;
